<template>
  <div class="detail-info-box" :class="{ old: isElder }">
    <div class="detail-info-title">基本信息</div>
    <div class="detail-info-main">
      <dl>
        <dt>运营单位</dt>
        <dd>桐庐垂云通天河旅游有限公司</dd>
      </dl>
      <dl>
        <dt>法人代表</dt>
        <dd>*晓红</dd>
      </dl>
      <dl>
        <dt>运营期限</dt>
        <dd>1998年7月6日至2048年7月5日</dd>
      </dl>
      <dl>
        <dt>安全风险评估单位</dt>
        <dd>河南鑫安利职业健康科技有限公司</dd>
      </dl>
      <dl>
        <dt>景区地址</dt>
        <dd>浙江省杭州市桐庐县瑶琳镇东琳村</dd>
      </dl>
      <dl>
        <dt>安全负责人</dt>
        <dd>安全员和安全负责人各1人，均取得安全培训证书，但未进行再培训</dd>
      </dl>
      <dl>
        <dt>建设设施</dt>
        <dd>玻璃吊桥、玻璃滑道、玻璃悬廊</dd>
      </dl>
      <dl>
        <dt>应急预案</dt>
        <dd>编有突发事故应急预案</dd>
      </dl>
      <dl>
        <dt>特种作业</dt>
        <dd>电气作业，配备两名电工，已取得特种作业证</dd>
      </dl>
    </div>
  </div>
</template>
<script>
// import api from "@/api/my/index";
export default {
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  methods: {
    //获取景区信息
    getData() {},
  },
};
</script>
<style lang="less" scoped>
dl,dt,dd {
  margin: 0;
  padding: 0;
}
.detail-info {
  &-box {
    background-color: #fff;
  }
  &-title {
    background: #f6f7f8;
    line-height: 1;
    font-size: 16px;
    padding: 20px 16px 15px;
    display: flex;
    &::before {
      content: "";
      height: 16px;
      border-left: 4px solid #1c91ff;
      padding-right: 6px;
    }
  }
  &-main {
    padding: 20px;
    dl {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
    dt {
      font-size: 15px;
      color: #363a44;
      line-height: 1.5;
      margin-bottom: 5px;
    }
    dd {
      background: #f6f7f8;
      border-radius: 8px;
      font-size: 15px;
      line-height: 1.5;
      padding: 12px 15px;
      color: #686B73;
    }
  }
}
.old {
  font-weight: bold;
  .detail-info-title {
    font-size: 22px;
    &::before {
      content: "";
      height: 22px;
      border-left: 4px solid #1c91ff;
      padding-right: 6px;
    }
  }
  .detail-info-main {
    & dl {
      & dt, & dd {
        font-size: 20px !important;
      }
    }
  }
}
</style>
